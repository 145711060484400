import { Maybe } from '@/lib/misc/utils/types'

/**
 * Executes the callback function with handling all the errors.
 * @param callback Callback function to execute.
 * @returns Callback function execution result.
 */
export const handleError = <TCallback extends () => any>(
    callback: TCallback
): ErrorHandlerReturnType<TCallback> => {
    try {
        const result = callback()

        if (result instanceof Promise) {
            const awaitResult = async (): Promise<IErrorHandledFunctionOutput<any>> => {
                try {
                    const awaitedResult = await result

                    return {
                        error: null,
                        result: awaitedResult
                    }
                } catch (err: any) {
                    return {
                        error: err,
                        result: null
                    }
                }
            }

            return awaitResult() as any
        }

        return {
            error: null,
            result
        } as any
    } catch (err: any) {
        return {
            error: err,
            result: null
        } as any
    }
}

type ErrorHandlerReturnType<
    TCallback extends () => any | Promise<any>
> = ReturnType<TCallback> extends Promise<infer P>
    ? Promise<IErrorHandledFunctionOutput<P>>
    : IErrorHandledFunctionOutput<ReturnType<TCallback>>

export interface IErrorHandledFunctionOutput<T> {
    error: Maybe<Error>
    result: T
}
